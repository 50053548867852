const refsCommon = {
	// //modals
	acceptCookie: document.getElementById("acceptCookie"),

	// //sections

	// forms
	form: document.getElementById("form"),
};

export default refsCommon;
